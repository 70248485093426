// BatchAmountContext.tsx
import { createContext, useContext, useEffect, useRef, useState, ReactNode } from 'react';
import { SendWebSocketMessage, useWebSocket } from './WebsocketContext';

interface BatchAmounts {
    redBatchAmount: number;
    blueBatchAmount: number;
    lastRedBatchAmount: number;
    lastBlueBatchAmount: number;
    totalSessionClicks: number;
    incrementRed: () => void;
    incrementBlue: () => void;
}

const defaultValue: BatchAmounts = {
    redBatchAmount: 0,
    blueBatchAmount: 0,
    lastRedBatchAmount: 0,
    lastBlueBatchAmount: 0,
    totalSessionClicks: 0,
    incrementRed: () => { },
    incrementBlue: () => { },
};

// Insert your createContext line here with the defaultValue
const BatchAmountContext: React.Context<BatchAmounts> = createContext(defaultValue);

type WebSocketProviderProps = {
    children: ReactNode;
};

export const BatchAmountProvider: React.FC<WebSocketProviderProps> = ({ children }) => {

    const { sendMessage, lastUpdate } = useWebSocket();

    const [redBatchAmount, setRedBatchAmount] = useState(0);
    const [blueBatchAmount, setBlueBatchAmount] = useState(0);
    const [lastRedBatchAmount, setLastRedBatchAmount] = useState(0);
    const [lastBlueBatchAmount, setLastBlueBatchAmount] = useState(0);
    const [trigger, setTrigger] = useState(false);
    const [totalSessionClicks, setTotalSessionClicks] = useState(0);

    const incrementRed = () => {
        setRedBatchAmount(prev => prev + 1);
        setTotalSessionClicks(prev => prev + 1);
        // console.log('Red Batch Amount:', redBatchAmount);
    };

    const incrementBlue = () => {
        setBlueBatchAmount(prev => prev + 1);
        setTotalSessionClicks(prev => prev + 1)
        // console.log('Blue Batch Amount:', blueBatchAmount);
    };

    const resetBatches = () => {
        setRedBatchAmount(0);
        setBlueBatchAmount(0);
    };

    // SEND CLICK BATCH EVERY 3 SECONDS
    const sendBatchThroughWebsocket = (redBatchAmount: number, blueBatchAmount: number) => {
        if (redBatchAmount > 0 || blueBatchAmount > 0) {
            const message: SendWebSocketMessage = {
                type: 'buttonPress',
                data: { redButtonPresses: redBatchAmount, blueButtonPresses: blueBatchAmount }
            };
            sendMessage(message);
            // console.log(`Sending click batch: Red: ${redBatchAmount}, Blue: ${blueBatchAmount}`);
            setLastRedBatchAmount(redBatchAmount);
            setLastBlueBatchAmount(blueBatchAmount);
        }
    };

    // Update state based on incoming WebSocket messages
    useEffect(() => {
        if (lastUpdate) {
            try {
                const messageData = JSON.parse(lastUpdate.data);
                if (messageData.type === 'update') {
                    // Handle incoming message, update accordingly
                    setTrigger(prev => !prev);  // Toggle the trigger state
                }
            } catch (error) {
                console.error("Failed to parse WebSocket message:", error);
            }
        }
    }, [lastUpdate]);

    useEffect(() => {
        sendBatchThroughWebsocket(redBatchAmount, blueBatchAmount);  // Call your external API
        resetBatches();     // Reset batch counts after the API call
    }, [trigger]);  // Dependencies ensure updates are based on latest state

    const providerValue = {
        redBatchAmount,
        blueBatchAmount,
        lastRedBatchAmount,
        lastBlueBatchAmount,
        totalSessionClicks,
        incrementRed,
        incrementBlue,
    };

    return (
        <BatchAmountContext.Provider value={providerValue}>
            {children}
        </BatchAmountContext.Provider>
    );
};

export const useBatchAmount = () => useContext(BatchAmountContext);
