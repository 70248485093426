import './JumpingNumber.css'

import React, { useEffect, useState } from 'react'

interface JumpingNumberProps {
    number: number;
    color?: 'red' | 'blue' | 'purple' | 'white' | 'pink';
    growthCoefficient?: number;
    initialFontSize?: number;
}

const JumpingNumber: React.FC<JumpingNumberProps> = ({ number, color = 'white', growthCoefficient = 1, initialFontSize = 2 }) => {

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {

        // Set the active state to trigger the animation
        setIsActive(true);

        // Update the displayed number with a timeout to simulate 'jumping'
        const timer = setTimeout(() => {
            setIsActive(false);
        }, 100); // Match the duration of the CSS animation

        return () => clearTimeout(timer);
    }, [number]);

    // When number changes, activate the jumping css animation
    // Also the number should be from 0 to 30, lets make the number get bigger with the number size
    // We should also take into consideration the size of the screen

    // Make initialFontSize half if screen is smaller than 750px wide
    const screenWidth = window.innerWidth;
    const initialFontSizeScreenAdjusted = screenWidth < 750 ? initialFontSize / 1.8 :
        screenWidth < 1000 ? initialFontSize / 1.5 :
            screenWidth < 1400 ? initialFontSize / 1.2 :
                initialFontSize;

    const fontSize = initialFontSizeScreenAdjusted + (number * growthCoefficient);

    const style = { fontSize: `${fontSize}rem` }

    return (
        <div
            className={`JumpingNumber${isActive && number !== 0 ? ' jumping' : ''} ${color}`}
            style={style}
        >
            {number}
        </div>
    );
}

export default JumpingNumber;