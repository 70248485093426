// src/contexts/WebSocketContext.tsx

import { createContext, useContext, useEffect, useRef, useState, ReactNode } from 'react';
import config from '../config';

export interface ReceiveWebSocketMessage {
    type: string;
    data: ReceiveButtonPressData;
}

export interface SendWebSocketMessage {
    type: string;
    data: SendButtonPressData;
}

// Define specific data structures for each message type
export interface ReceiveButtonPressData {
    redButtonPresses: number;
    blueButtonPresses: number;
    secondsUntilDoom: number;
}

export type SendButtonPressData = {
    redButtonPresses: number;
    blueButtonPresses: number;
};

interface WebSocketContextType {
    sendMessage: (message: SendWebSocketMessage) => void;
    lastMessage: MessageEvent | null;
    lastUpdate: MessageEvent | null;
    isConnected: boolean;
}

// Default values
const defaultValue: WebSocketContextType = {
    sendMessage: () => { },
    lastMessage: null,
    lastUpdate: null,
    isConnected: false,
};

const WebSocketContext = createContext<WebSocketContextType>(defaultValue);

export const useWebSocket = () => useContext(WebSocketContext);

type WebSocketProviderProps = {
    children: ReactNode;
};

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
    const [lastMessage, setLastMessage] = useState<MessageEvent | null>(null);
    const [lastUpdate, setLastUpdate] = useState<MessageEvent | null>(null);
    const [isConnected, setIsConnected] = useState(false);
    const websocket = useRef<WebSocket | null>(null);


    useEffect(() => {
        const connectWebSocket = () => {
            const ws = new WebSocket(config.BASE_WS_URL);

            ws.onopen = () => {
                console.log('WebSocket Connected');
                setIsConnected(true);
            };

            ws.onmessage = (event) => {
                // console.log('WebSocket Message Received:', event.data);
                const eventData: ReceiveWebSocketMessage = JSON.parse(event.data);
                if (eventData.type === 'update') {
                    setLastUpdate(event);
                    return;
                } else if (eventData.type === 'initialPuushData') {
                    setLastMessage(event);
                    return;
                };
            };

            ws.onclose = (event) => {
                console.log('WebSocket Disconnected:', event.reason);
                setIsConnected(false);
                // setTimeout(connectWebSocket, 3000);  // Reconnect after 3 seconds
            };

            ws.onerror = (error) => {
                console.error('WebSocket Error:', error);
                ws.close();
            };

            websocket.current = ws;

        };

        connectWebSocket();

        return () => {
            websocket.current?.close();
        };
    }, []);

    const sendMessage = (message: SendWebSocketMessage) => {
        if (websocket.current && isConnected) {
            websocket.current.send(JSON.stringify(message));
        }
    };

    return (
        <WebSocketContext.Provider value={{ sendMessage, lastMessage, lastUpdate, isConnected }}>
            {children}
        </WebSocketContext.Provider>
    );
};