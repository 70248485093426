// App.tsx
import { useEffect, useState } from 'react';
import './App.css';
import config from './config';
import PuushButton from './components/PuushButton/PuushButton';
import ButtonZones from './components/ButtonZones/ButtonZones';
import Sparks from './components/Sparks/Sparks';
import Scoreboard from './components/Scoreboard/Scoreboard';
import SocialsBar from './components/SocialsBar/SocialsBar';
import { ReceiveButtonPressData, ReceiveWebSocketMessage, useWebSocket } from './contexts/WebsocketContext';
import { useBatchAmount } from './contexts/BatchAmountContext';
import JumpingNumber from './components/JumpingNumber/JumpingNumer';
import MemeText from './components/MemeText/MemeText';
import CustomIcon from './components/CustomIcon/CustomIcon';
import DoomCounter from './components/DoomCounter/DoomCounter';
import { MemeImages } from './components/MemeImages/MemeImages';
import { VersionNumber } from './components/VerionNumber/VersionNumber';
import DonoProgressBar from './components/DonoProgressBar/DonoProgressBar';
import useMousePosition from './hooks/useMousePosition';
import useCountdown from './hooks/useCountdown';
import EmojiCountdown from './components/EmojiCountdown/EmojiCountdown';
import MaintenanceButton from './components/MaintenanceButton/MaintenanceButton';
import TopBar from './components/TopBar/TopBar';

export type location = { x: number, y: number };

export type DoomTimerValue = {
    value: number;
    version: number;
}

function App() {

    // Load Websocket context
    const { sendMessage, isConnected, lastMessage, lastUpdate } = useWebSocket();
    // Mouse position hook
    const mouseLocation = useMousePosition();
    // Batch amount context
    const { redBatchAmount,
        blueBatchAmount,
        lastRedBatchAmount,
        lastBlueBatchAmount,
        incrementRed,
        incrementBlue,
        totalSessionClicks
    } = useBatchAmount();

    const [leftButtonPressed, setLeftButtonPressed] = useState(false);
    const [rightButtonPressed, setRightButtonPressed] = useState(false);
    const [redBoardValue, setRedBoardValue] = useState(0);
    const [blueBoardValue, setBlueBoardValue] = useState(0);
    const [doomTimerValue, setDoomTimerValue] = useState<DoomTimerValue>({ value: 0, version: 0 });

    const handleLeftButtonState = (state: boolean) => {
        setLeftButtonPressed(state);
        if (state) {
            incrementRed();
        }
    };

    const handleRightButtonState = (state: boolean) => {
        setRightButtonPressed(state);
        if (state) {
            incrementBlue();
        }
    };

    useEffect(() => {
        // You can abstract the common functionality to update state
        const updateState = (data: ReceiveButtonPressData) => {
            setRedBoardValue(data.redButtonPresses);
            setBlueBoardValue(data.blueButtonPresses);
            setDoomTimerValue(prevState => ({
                value: data.secondsUntilDoom,
                version: prevState.version + 1 // increment version to force update
            }));
        };

        // Check if message is an update first, then initial data - we do update first because it should take precedence over initial data
        const message: ReceiveWebSocketMessage = lastUpdate ? JSON.parse(lastUpdate.data) : lastMessage ? JSON.parse(lastMessage.data) : null;
        if (message) {
            if (message.type === 'initialPuushData' || message.type === 'update') {
                // console.log('Received message:', message);
                const data: ReceiveButtonPressData = message.data;
                updateState(data);
            }
        }
    }, [lastMessage, lastUpdate]);  // Depend on both lastMessage and lastUpdate


    return (
        <>
            <TopBar />
            <div className="App-main">
                <Sparks startLocation={mouseLocation} startAnimation={leftButtonPressed} color={'red'} />
                <Sparks startLocation={mouseLocation} startAnimation={rightButtonPressed} color={'blue'} />
                <div className='App-stuff-container'>
                    <div className='App-image-container non-selectable'>
                        <JumpingNumber number={redBatchAmount} color={'red'} growthCoefficient={0.2} />
                        <JumpingNumber number={blueBatchAmount} color={'blue'} growthCoefficient={0.2} />
                        <JumpingNumber number={totalSessionClicks} color={'pink'} growthCoefficient={0.02} />
                        <Scoreboard redValue={redBoardValue + lastRedBatchAmount} blueValue={blueBoardValue + lastBlueBatchAmount} isConnected={isConnected} />
                        <img src={config.WEB_IMAGE_URL + 'puush_background_half.webp'} className="App-image" alt="" draggable="false" />
                        <PuushButton side={'left'} isPressed={leftButtonPressed} isConnected={isConnected} />
                        <PuushButton side={'right'} isPressed={rightButtonPressed} isConnected={isConnected} />
                        <MemeImages />
                        {/* <MaintenanceButton side={'right'} isConnected={isConnected} /> */}
                        <ButtonZones
                            setLeftButtonPressed={handleLeftButtonState}
                            setRightButtonPressed={handleRightButtonState}
                            isConnected={isConnected}
                        />
                        <DoomCounter doomTimeInSeconds={doomTimerValue} isConnected={isConnected} />
                    </div>
                    🐱🔴🔵🐶
                    {/* <EmojiCountdown emoji={'⚖️'} unixTimestamp={1715399999} /> */}
                    {/* <DonoProgressBar /> */}
                    <MemeText totalSessionClicks={totalSessionClicks} />
                    <div className='App-text-link'>
                        PUUSH DA BUTTON
                    </div>
                    <SocialsBar />
                    <a
                        href='https://swap.ebisusbay.com/#/swap?outputCurrency=0x288898a6057d2D4989c533E96Cb3bc30843c91D7'
                        target="blank"
                    >
                        <div className='App-text-link underline non-selectable'>
                            $PUUSH <CustomIcon name="opennew" size={25} />
                        </div>
                    </a>
                    <VersionNumber />
                </div>
            </div>
        </>
    );
}

export default App;
