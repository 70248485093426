import { useState, useEffect } from 'react';

function useMousePosition() {
    const [mouseLocation, setMouseLocation] = useState({ x: 0, y: 0 });

    useEffect(() => {
        // Custom throttle function
        let waiting = false;
        const handleMouseMove = (event: MouseEvent) => {
            if (!waiting) {
                setMouseLocation({ x: event.clientX, y: event.clientY });
                waiting = true;
                // Adjust the timeout to control frequency
                setTimeout(() => {
                    waiting = false;
                }, 100); // Throttle rate: 100ms
            }
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return mouseLocation;
}

export default useMousePosition;
