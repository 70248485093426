import React from 'react';
import './TopBar.css'; // Make sure to create this CSS file for styling

const TopBar: React.FC = () => {
    return (
        <div className="top-bar">
            <p>Make sure you are visiting</p>
            <p><strong>https://puushdabutton.com/</strong></p>
            <p>We will not ask you to connect your wallet on this site.</p>
        </div>
    );
};

export default TopBar;
