import './MemeText.css'

import React, { useEffect, useState } from 'react'

interface MemeTextProps {
    totalSessionClicks: number;
}

const MemeText: React.FC<MemeTextProps> = ({ totalSessionClicks }) => {

    const [text, setText] = useState('PUUSH');

    useEffect(() => {
        switch (totalSessionClicks) {
            case 0:
                setText('PUUSH DA BUTTON');
                break;
            case 2:
                setText('PUUUSH DA BUTTON');
                break;
            case 5:
                setText('PUUUUSH DA BUTTON');
                break;
            case 10:
                setText('PUUUUUUUUSH')
                break;
            case 13:
                setText('UNLUCKY PUUSH')
                break;
            case 14:
                setText('PUUUUUUUUUSH')
                break;
            case 20:
                setText('PUUSH BETTER...')
                break;
            case 40:
                setText('OK PUUSH')
                break;
            case 69:
                setText('BEST PUUSH ;)')
                break;
            case 70:
                setText('OK PUUSH')
                break;
            case 80:
                setText('PUUSH WARMING UP')
                break;
            case 90:
                setText('DO YOU EVEN PUUSH')
                break;
            case 100:
                setText('PUUSH IT REAL GUD')
                break;
            case 150:
                setText('DID YOU READ THE INSTRUCTIONS?')
                break;
            case 210:
                setText('NIIIICE PUUSH')
                break;
            case 320:
                setText('PUUSH HARDER')
                break;
            case 420:
                setText(':)')
                break;
            case 422:
                setText('KINDA HUNGRY NOW')
                break;
            case 431:
                setText('KINDA SLEEPY NOW')
                break;
            case 441:
                setText('WAKE UP')
                break;
            case 451:
                setText('PUUSH HARDER')
                break;
            case 640:
                setText('FEELS GOOD PUUSH')
                break;
            case 666:
                setText('SCARY SPOOKY')
                break;
            case 669:
                setText('FEELS GOOD PUUSH')
                break;
            case 1280:
                setText('IS THIS BUTTON ON')
                break;
            case 1337:
                setText('LEET PUUSH')
                break;
            case 1338:
                setText('STOP ALREADY!')
                break;
            case 1349:
                setText('JK KEEP PUUSHING')
                break;
            case 2560:
                setText('UNSTOPABLE PUUSH')
                break;
            case 5120:
                setText('GODLY PUUSH')
                break;
            case 10240:
                setText('INCONCEIVABLE PUUSH')
                break;
            case 20480:
                setText('ABSOLUTE UNCONTESTED MASTER PUUSH')
                break;
            case 40960:
                setText('OK STOP FFS')
                break;
            case 81920:
                setText('NO REALLY STOP')
                break;
            case 163840:
                setText('h')
                break;

        };
    }, [totalSessionClicks]);

    return (
        <div className="MemeText">
            {text}
        </div>
    );
}

export default MemeText;