import './ButtonZones.css';

interface ButtonZonesProps {
    setLeftButtonPressed: (value: boolean) => void;
    setRightButtonPressed: (value: boolean) => void;
    isConnected: boolean;
}

interface ZoneProps {
    side: 'left' | 'right';
    setPressed: (value: boolean) => void;
};

const ButtonZones: React.FC<ButtonZonesProps> = ({ setLeftButtonPressed, setRightButtonPressed, isConnected }) => {
    return (
        <div className="ButtonZones-container">
            {
                isConnected &&
                <>
                    <Zone side="left" setPressed={setLeftButtonPressed} />
                    <Zone side="right" setPressed={setRightButtonPressed} />
                </>
            }
        </div>
    );
}

const Zone: React.FC<ZoneProps> = ({ side, setPressed }) => {

    const handleMouseDown = () => setPressed(true);
    const handleMouseUp = () => setPressed(false);
    const handleMouseLeave = () => {
        setPressed(false); // Ensures the button is not stuck in pressed state on mobile
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        e.preventDefault(); // Prevents mouse events on touch devices
        setPressed(true);
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        e.preventDefault(); // Prevents mouse events on touch devices
        setPressed(false);
    };

    const handleTouchCancel = (e: React.TouchEvent) => {
        e.preventDefault();
        setPressed(false);
    };

    return (
        <div
            className={`ButtonZones-zone ${side}`}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchCancel={handleTouchCancel}
        >
        </div>
    );
};

export default ButtonZones;