type REACT_APP_ENV = 'development' | 'production';

// Ensure we have a valid config for the given environment.
if (!process.env.REACT_APP_ENV) {
    throw new Error('REACT_APP_ENV must be set');
}

interface CommonConfig {
    WEB_IMAGE_URL: string;
    SOCIALS: {
        TWITTER: string;
        TELEGRAM: string;
        DISCORD: string;
        CONTRACT: string;
        BURN: string;
    };
}

interface EnvironmentConfig {
    BASE_WS_URL: string;
    BASE_HTTP_URL: string;
}

type MergedConfig = CommonConfig & EnvironmentConfig;

const commonConfig: CommonConfig = {
    WEB_IMAGE_URL: 'https://puushdabutton-web-images.s3.amazonaws.com/',
    SOCIALS: {
        TWITTER: 'https://twitter.com/PUUSHDABUTTON',
        TELEGRAM: 'https://t.me/puushdabuttonportal',
        DISCORD: 'https://discord.gg/boomer-squad-nft-939820143486320650',
        CONTRACT: 'https://cronoscan.com/token/0x288898a6057d2D4989c533E96Cb3bc30843c91D7',
        BURN: 'https://cronoscan.com/tx/0x12700d4bfae3e357b2ad2f8e968a85e0a846766a3a1aa8269a428c4ad0fb6955',
    },
};

const devConfig: EnvironmentConfig = {
    BASE_WS_URL: 'ws://localhost:3002',
    BASE_HTTP_URL: 'http://localhost:3002',
};

const prodConfig: EnvironmentConfig = {
    BASE_WS_URL: 'wss://api.boomersquad.io',
    BASE_HTTP_URL: 'https://api.boomersquad.io',
};

// Ensure that the value of REACT_APP_ENV is one of the accepted values.
const env: REACT_APP_ENV = process.env.REACT_APP_ENV as REACT_APP_ENV;

const config: MergedConfig = {
    ...commonConfig,
    ...(env === 'development' ? devConfig : prodConfig),
};

export default config;
