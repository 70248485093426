import React, { useEffect, useState } from 'react';
import './DoomCounter.css';
import CustomIcon from '../CustomIcon/CustomIcon';
import { DoomTimerValue } from '../../App';

type DoomCounterProps = {
    doomTimeInSeconds: DoomTimerValue;
    isConnected: boolean;
};

const DoomCounter: React.FC<DoomCounterProps> = ({ doomTimeInSeconds, isConnected }) => {

    const [timeLeft, setTimeLeft] = useState<number>(doomTimeInSeconds.value); // total seconds left
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [milliseconds, setMilliseconds] = useState(0);

    useEffect(() => {
        // If doomTimeInSeconds is less than 0, set time left to 0
        if (doomTimeInSeconds.value < 0) {
            setTimeLeft(0);
        } else {
            setTimeLeft(doomTimeInSeconds.value);
        };
    }, [doomTimeInSeconds.version]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime > 0 ? prevTime - 0.01 : 0);
        }, 10);
        return () => clearInterval(intervalId);
    }, []);

    const formatTimeUnit = (unit: number) => {
        return unit < 10 ? `0${unit}` : unit.toString();
    };

    useEffect(() => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = Math.floor(timeLeft % 60);
        const milliseconds = Math.floor((timeLeft % 1) * 100);
        setMinutes(minutes);
        setSeconds(seconds);
        setMilliseconds(milliseconds);
    }, [timeLeft]);

    return (
        <div className={`DoomCounter-container`}>
            <div className={`DoomCounter ${isConnected ? '' : 'notConnected'}`}>
                <div className="countdown">
                    <CustomIcon name='timeWarning' size={30} />
                    {/* <div className='DoomCounter-emoji'>🔥</div> */}
                    <div className="time-unit">{isConnected ? formatTimeUnit(minutes) + 'm' : '? m'}</div>
                    <div className="time-unit">{isConnected ? formatTimeUnit(seconds) + 's' : '? s'}</div>
                    <div className="time-unit">{isConnected ? formatTimeUnit(milliseconds) + 'ms' : '? ms'}</div>
                </div>
            </div>
        </div>
    );
};

export default DoomCounter;