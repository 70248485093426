import React, { useState, useEffect } from 'react';
import './Sparks.css';

type SparkProps = {
    startLocation: { x: number, y: number };
    startAnimation: boolean;
    color: 'red' | 'blue';
};

const Sparks: React.FC<SparkProps> = ({ startLocation, startAnimation, color }) => {

    function createSpark() {
        const sparksContainer = document.querySelector('.Sparks-container');
        if (!sparksContainer) return;

        const xTranslate = Math.random() * 400 - 200;
        const initialYUp = Math.random() * 150 + 25;
        const scale = Math.random() * 1 + 0.5;
        // Make the finalYDown a little more than height of screen to show accumulation at bottom

        // window height
        const windowHeight = document.documentElement.clientHeight

        const finalYDown = windowHeight - startLocation.y - 10

        // Create a sanitized animation name
        const rawAnimationName = `spark-animation-${new Date().getTime()}-${Math.random()}`;
        const animationName = rawAnimationName.replace(/[^\w-]/g, ''); // Replace non-word characters and non-hyphens

        const styleSheet = document.styleSheets[0];

        const flyUpMark = 25;
        const gravityEffectPoints = flyUpMark / 100 * 99;

        const ruleText = `
            @keyframes ${animationName} {
                0%, ${flyUpMark}% {
                    opacity: 0.5;
                    transform: translate(0px, 0px) scale(${scale});
                }
                ${gravityEffectPoints}% {
                    opacity: 1;
                    transform: translate(${xTranslate / 100 * 35}px, -${initialYUp}px);
                }
                ${flyUpMark}% {
                    opacity: 1;
                    transform: translate(${xTranslate / 100 * 37}px, -${initialYUp}px);
                }
                100% {
                    opacity: 0.2;
                    transform: translate(${xTranslate}px, ${finalYDown}px) scale(1);
                }
            }`;

        try {
            styleSheet.insertRule(ruleText, styleSheet.cssRules.length);
        } catch (e) {
            console.error('Failed to insert rule:', e);
            console.log('Rule Text:', ruleText);
            return;
        }

        const spark = document.createElement('div');
        spark.className = `Spark ${color}`;
        spark.style.left = `${startLocation.x}px`;
        spark.style.top = `${startLocation.y}px`;
        spark.style.transform = `scale(${scale})`;
        spark.style.animation = `${animationName} 1.2s ease-in-out forwards`;

        sparksContainer.appendChild(spark);

        setTimeout(() => {
            spark.remove();
            // Optionally remove the CSS rule here as well
        }, 1500);
    }


    useEffect(() => {
        if (startAnimation) {
            // console.log(`Sparks starting at ${startLocation.x}, ${startLocation.y}`);
            const numSparks = Math.floor(Math.random() * 3) + 5; // Random number of sparks between 10 and 20
            for (let i = 0; i < numSparks; i++) {
                createSpark();
            }
        }
    }, [startAnimation]);

    return (
        <div className="Sparks-container" />
    );
};


export default Sparks;
