import React from 'react';
import './Scoreboard.css';
import JumpingNumber from '../JumpingNumber/JumpingNumer';

type ScoreboardProps = {
    redValue: number;
    blueValue: number;
    isConnected: boolean;
};

const Scoreboard: React.FC<ScoreboardProps> = ({ redValue, blueValue, isConnected }) => {

    return (
        <div className={`Scoreboard-container`}>
            <div className={`Scoreboard red ${isConnected ? '' : 'notConnected'}`}>{isConnected ? <JumpingNumber number={redValue} growthCoefficient={0} /> : '?'}</div>
            <div className={`Scoreboard blue ${isConnected ? '' : 'notConnected'}`}>{isConnected ? <JumpingNumber number={blueValue} growthCoefficient={0} /> : '?'}</div>
        </div>
    );
};

export default Scoreboard;