import config from '../../config';
import CustomIcon from '../CustomIcon/CustomIcon';
import './SocialsBar.css';

const SocialsBar: React.FC = () => {
    // Telegram, Twitter, Discord
    const size = 40;

    return (
        <div className="SocialsBar-container non-selectable">
            <CustomIcon name="discord" size={size} linkUrl={config.SOCIALS.DISCORD} />
            <CustomIcon name="twitter" size={size} linkUrl={config.SOCIALS.TWITTER} />
            <CustomIcon name="telegram" size={size} linkUrl={config.SOCIALS.TELEGRAM} />
            <CustomIcon name="contract" size={size} linkUrl={config.SOCIALS.CONTRACT} />
            <CustomIcon name="burn" size={size} linkUrl={config.SOCIALS.BURN} />
        </div>
    );
};

export default SocialsBar;