import React from 'react';
import config from '../../config';
import './PuushButton.css';

interface PuushButtonProps {
    side: 'left' | 'right';
    isPressed: boolean;
    isConnected: boolean;
}

const PuushButton: React.FC<PuushButtonProps> = ({ side, isPressed, isConnected }) => {

    const getPressedString = isPressed ? 'down' : 'up';
    const buttonImageSrc = `${config.WEB_IMAGE_URL}puush_button_${side}_${getPressedString}_half.webp`;

    return (
        <div
            className={`PuushButton-container`}
        >
            <img
                src={buttonImageSrc}
                className={`App-image ${isConnected ? '' : 'notConnected'}`}
                alt={`${side} button ${getPressedString}`}
            />
        </div>
    );
};

export default PuushButton;
