import config from '../../config';
import './MemeImages.css';

export const MemeImages = () => {
    return (
        <div className="MemeImages-container">
            {/* <img className='MemeImage blue' src={config.WEB_IMAGE_URL + 'tp_red.png'} alt="tp" />
            <img className='MemeImage red' src={config.WEB_IMAGE_URL + 'tp_blue.png'} alt="tp" /> */}
            {/* <img className='MemeImage scale' src={config.WEB_IMAGE_URL + 'scale.png'} alt="scale" /> */}
            <img className='MemeImage cat' src={config.WEB_IMAGE_URL + 'cat.png'} alt="cat" />
            <img className='MemeImage dog' src={config.WEB_IMAGE_URL + 'dog.png'} alt="dog" />
        </div>
    );
};